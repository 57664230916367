import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap';

import Seo from "../components/seo";
import Layout from "../components/layout";

import "../styles/global/base.scss";
import "../styles/privacy-policy.scss";

const PrivacyPolicyPage = () => {

  return (
    <React.Fragment>
      <Seo title="PRIVACY POLICY" pageClass="privacyPolicyPage" />
      <Layout>
        <Container fluid className="privacyPolicyHeader">
          <Row>
            <Col>
              <h1>PRIVACY POLICY</h1>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col md={{ span: 8, offset: 2 }} className="privacyPolicyContent">
              <p>
                株式会社モンタージュ（以下、モンタージュ）は、本Webサイトを運用するにあたり、お客様個人に関する情報（以下「個人情報」といいます）を次の通り取扱います。
              </p>
              <dl>
                <dt>1. 個人情報の取扱い</dt>
                <dd>
                  個人情報の取扱いについては、ご本人の権利や利益を不当に侵害しないように最大限配慮、尊重することが必要であると考え、社内体制を整備し、個人情報保護に関する法律など関係法令を順守し、特段の事情がない限りお客様の承認なく、第三者に開示・提供することはありません。
                </dd>
                <dt>2. 個人情報の利用目的</dt>
                <dd>
                  1. 利用目的<br />
                  モンタージュは、お客様からご入力いただいた個人情報を、ご本人様との取引先管理や最新情報のご案内、社内業務のために利用します。<br />
                  モンタージュから、お客様に有益と思われるサービス等の情報を電子メールや郵便等でお客様にお送りさせていただく場合がございます。ご不要な場合、お客様からご連絡をいただければ、このような送信・送付は停止させていただきます。
                </dd>
                <dd>
                  2. 利用する個人情報の項目<br />
                  お客様の氏名、住所、電話番号、FAX番号、Eメールアドレス、勤務先(会社名、所属部課、役職、住所、電話番号、FAX番号)、など。また、当Webサイトではアクセスログ解析に当たり以下の情報を各ページ閲覧時に収集致します。ただしこれらのアクセスログ情報は当Webサイトの質の向上、Webサイト制作の参考データとしてのみ利用し、この目的以外に使用されることはありません。
                  <ul>
                    <li>当Webサイトにアクセスした時間</li>
                    <li>IPアドレス</li>
                    <li>ホスト名</li>
                    <li>使用OS／ブラウザ</li>
                    <li>リンク元</li>
                    <li>検索キーワード</li>
                  </ul>
                </dd>
              </dl>
            </Col>
          </Row>
        </Container>
      </Layout>
    </React.Fragment>
  );
};

export default PrivacyPolicyPage;
